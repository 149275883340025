import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../store/actions/authActions";

const Auth = ()=>{
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(authenticateUser({email: process.env.REACT_APP_CX_USER_EMAIL, password: process.env.REACT_APP_CX_USER_PASSWORD}))
    }, [])
    return <Box>
        Auth page
    </Box>
}

export default Auth;