import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACTION_TYPES } from "./types";
import axiosInstance from "../../services/api";

axios.defaults.baseURL = "https://cx.api.averly.com.na";

const fetchData = async (
  endpoint,
  params,
  singleResponse,
  dispatch,
  actionType
) => {
  try {
    const res = await axiosInstance.get(endpoint, { params });
    const payload = singleResponse
      ? res.data.length
        ? res.data[0]
        : {}
      : res.data;
    toast.success(endpoint + ": Data fetched successfully");
    dispatch({ type: actionType, payload });
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    throw err;
  }
  dispatch({ type: "SET_LOADING", payload: false });
};

//Get report types
export const getReportTypes = () => async (dispatch) => {
  const baseUrl =
    process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
  const endpoint = `${baseUrl}/qm/reportTypes`;
  const actionType = ACTION_TYPES.GET_REPORT_TYPES;
  const singleResponse = 0;
  await fetchData(endpoint, {}, singleResponse, dispatch, actionType);
};

// get reportsData
export const getReportsData = (id) => async (dispatch) => {
  dispatch({ type: "SET_LOADING", payload: true }); //This could go directkly to getData
  const baseUrl =
    process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
  const endpoint = `${baseUrl}/qm/reports`;
  const payload = {
    id,
  };
  const actionType = ACTION_TYPES.GET_REPORTS_DATA;
  const singleResponse = 0;
  toast.info("Fetching data");
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  dispatch({ type: "SET_LOADING", payload: false });
};

//GET_CONFIG
export const getConfig = (organizationId) => async (dispatch) => {
  const endpoint = "/admin/styling/cf";
  const payload = { organizationId };
  const actionType = ACTION_TYPES.GET_CONFIG;
  const singleResponse = 1;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

//MAILING_LIST

export const getMailingList = (id) => async (dispatch) => {
  const baseUrl =
    process.env.REACT_APP_REPORTS_API || "https://reports.api.averly.com.na";
  const endpoint = `${baseUrl}/reports/mailingList`;
  const payload = {
    id,
  };
  const actionType = ACTION_TYPES.GET_MAILING_LIST;
  const singleResponse = 1;
  toast.info("Fetching data");
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

//GET_LOGO
export const getLogoData = (storageId) => async (dispatch) => {
  const endpoint = `/apps/ds/storage`;
  const payload = { id: storageId };
  const actionType = ACTION_TYPES.GET_LOGO_DATA;
  const singleResponse = 1;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

export const clearReports = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.GET_REPORTS_DATA, payload: [] });
  dispatch({ type: ACTION_TYPES.GET_REPORT_TYPES, payload: [] });
};

//UPDATE_REPORT_RANGE
export const updateReportRange = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_REPORT_RANGE,
    payload,
  });
};
