import {
  Box,
  Text,
  Center,
  Image,
  VStack,
  Heading,
  Divider,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { REPORT_RANGES } from "../store/constants";

const Cover = () => {
  const { mailingList, logoData, config } = useSelector(
    (state) => state.defaultReducer
  );

  const {
    serviceName,
    reportRange,
    subscriptionName,
    dateRange,
    userFullName,
    email,
    toc,
    organizationId,
  } = mailingList;
  //const logoUrl = `https://cx.storage.averly.com.na/${organizationId}/${logoData.filename}`;
  const reportRangeStr = {
    day: "Daily",
    week: "Weekly",
    month: "Monthly",
  }[reportRange];

  let [start, end] = [dateRange[0]?.split("-"), dateRange[1]?.split("-")];
  [start, end] = [
    `${start[1]}-${start[0]}-${start[2]}`,
    `${end[1]}-${end[0]}-${end[2]}`,
  ];
  const period =
    reportRange === REPORT_RANGES.DAY
      ? `${dayjs(start, "MM-DD-YYYY").format("MMMM D, YYYY")}`
      : `${dayjs(start, "MM-DD-YYYY").format("MMMM D, YYYY")} to ${dayjs(
          end,
          "MM-DD-YYYY"
        ).format("MMMM D, YYYY")}`;

  const logos = [
    {
      organizationId: "0ASmnwonZfFrOhs6I3De",
      url: "https://cx.storage.averly.com.na/0ASmnwonZfFrOhs6I3De/6d01f853-2c94-4ab0-9360-3b52438f316e.jpg",
    },
    {
      organizationId: "cc229c19-320e-4c4b-9ef0-7e1085e1e49d",
      url: "https://cx.storage.averly.com.na/cc229c19-320e-4c4b-9ef0-7e1085e1e49d/d03ee51a-1c43-4d37-a631-2753eeb8d3e5.png",
    },
    {
      organizationId: "12346b65-a1f9-42c9-bff7-c23768882c02",
      url: "https://cx.storage.averly.com.na/12346b65-a1f9-42c9-bff7-c23768882c02/357b4efe-039c-4f89-ab9a-326bfa9a30cd.jpg",
    },

    {
      organizationId: "f82d60f7-b911-48b4-8f6e-769b72ba4aaf",
      url: "https://cx.storage.averly.com.na/f82d60f7-b911-48b4-8f6e-769b72ba4aaf/8149786f-f7ae-4a22-bd81-046cfd6ed4d8.png"
    },
    {
      organizationId: "D4tJC4RLy7oBWsBTzPGP",
      url: "https://cx.storage.averly.com.na/D4tJC4RLy7oBWsBTzPGP/f654359d-56c7-4118-a96f-08fea1e92522.png"
    }
  ];

  const logoUrl = logos.find((a) => a.organizationId === organizationId)?.url;

  return (
    <Box bg="white" p={10} borderRadius="md">
      <Center mt="60px">
        <VStack spacing={8}>
          <Image src={logoUrl} maxH="80px" id="companyLogo" />
          <Divider />
          <Heading
            as="h1"
            size="2xl"
            color="blue.500"
            fontWeight="bold"
            textAlign="center"
          >
            {reportRangeStr} Report
          </Heading>
          <VStack
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <Heading as="h2" size="lg" fontWeight="semibold">
                Period: {period}
              </Heading>
            </Box>
            <Box>
              <Heading as="h2" size="lg" fontWeight="semibold">
                Product: {serviceName}
              </Heading>
            </Box>
            <Box>
              <Heading as="h2" size="lg" fontWeight="semibold">
                Subscription Name: {subscriptionName}
              </Heading>
            </Box>
          </VStack>
          <Center mt={10}>
            <VStack spacing={2} alignItems="center">
              <Text fontSize="lg">{userFullName}</Text>
              <Link
                href={`mailto:${encodeURIComponent(email)}`}
                color="blue.500"
                textDecoration="underline"
              >
                {email}
              </Link>
            </VStack>
          </Center>
        </VStack>
      </Center>
    </Box>
  );
};

export default Cover;
