import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { ACTION_TYPES } from "./types";

axios.defaults.baseURL = "https://cx.api.averly.com.na";


export const authenticateUser = ({ email, password }) => async (dispatch) => {
  try {
    const res = await axios.post('/auth/authenticate', {
      email,
      password,
    });
    const userData = res.data;

    /// /cookies
    const { accessToken, id: userId } = userData;

    const { hostname } = window.location;
    const expires = 24 * 60 * 60 * 1000; // 24 hours
    const domain = hostname === 'localhost'
      ? 'localhost'
      : '.averly.com.na';
    const secure = hostname !== 'localhost'; // disable when on localhost

    Cookies.set('accessToken', accessToken, {
      expires,
      domain,
      // secure,
      // httpOnly: true,
      // sameSite: 'lax', // allow only domain and subdomains
    });

    Cookies.set('userId', userId, {
      expires,
      domain,
      // secure,
      // httpOnly: true,
      // sameSite: 'lax', // allow only domain and subdomains
    });

    console.log('Successfully logged in');
  } catch (err) {
    console.error('Invalid Email or Password');
  }
};

export const updateAppStatus = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_APP_STATUS,
    payload,
  });
};