// Auth
export const FETCH_CLOUD_ORGANIZATIONS = "FETCH_CLOUD_ORGANIZATIONS";

//Add all action types here
export const ACTION_TYPES = Object.freeze({
  UPDATE_REPORT_DURATION: "UPDATE_REPORT_DURATION",
  UPDATE_APP_STATUS: "UPDATE_APP_STATUS",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  SET_USER_ID: "SET_USER_ID",
  GET_REPORT_TYPES: "GET_REPORT_TYPES",
  GET_REPORTS_DATA: "GET_REPORTS_DATA",
  GET_MAILING_LIST: "GET_MAILING_LIST",
  GET_CONFIG: "GET_CONFIG",
  GET_LOGO_DATA: "GET_LOGO_DATA",
  UPDATE_REPORT_RANGE: "UPDATE_REPORT_RANGE",

  // auth reducer
  SET_USER_DATA: "SET_USER_DATA",
  SET_LOADING: "SET_LOADING",
});
